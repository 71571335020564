.modal--backdrop {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.modal--container {
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(121.35deg, rgba(229, 24, 186, 0.4) -12.07%, rgba(229, 24, 186, 0) 44.14%), #330A6B;
  box-shadow: 0px 0px 20px #E518BA;
  border-radius: 20px;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px 0px 0;
  overflow: hidden;
  width: 100%;

}

.modal-body {
  width: 100%;
  padding: 24px;
}

.modal--container>.item--container>.item-left>.item-left-text {
  margin-left: 24px;
}

.modal--container>.item--container>.item-right>.item-left-icon {
  margin-right: 24px;
}

.modal--container--navbar {
  z-index: 1001;
  position: fixed;
  top: 300%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(121.35deg, rgba(229, 24, 186, 0.4) -12.07%, rgba(229, 24, 186, 0) 44.14%), #330A6B;
  box-shadow: 0px 0px 20px #E518BA;
  border-radius: 20px;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px 0px 0;
  overflow: hidden;
  width: 480px;

}

.modal--container--navbar>.item--container>.item-left>.item-left-text {
  margin-left: 24px;
}

.modal--container--navbar>.item--container>.item-right>.item-left-icon {
  margin-right: 24px;
}

@media screen and (max-width: 1400px) {
  .modal--container {
    width: 100vw;
  }
  .modal--container--navbar{
    width: 100vw;
  }
}