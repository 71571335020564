:root {
  --font-family: "Montserrat";
  --primary: #7E23FC;
  --primary2: #E518BA;
  --primary3: #EB31CD;
  --primary4: #F66EF0;
  --primary5: #F79DFB;
  /* ////////////////////////// */
  --interface-dark: linear-gradient(121.35deg, rgba(229, 24, 186, 0.4) -12.07%, rgba(229, 24, 186, 0) 44.14%), #330A6B;
  --interface-light: linear-gradient(158.8deg, rgba(188, 239, 255, 0.176) 10.36%, rgba(188, 239, 255, 0) 42.31%), #F7F7FC;
  /* ////////////////////////// */
  --gradient-orange: linear-gradient(90.03deg, #E518BA 0.03%, #EB5B13 99.97%);
  --gradient-medium: linear-gradient(92.39deg, #7E23FC 5.08%, #FB34FF 96.4%);
  --gradient-light: linear-gradient(73.6deg, #85FFC4 2.11%, #5CC6FF 42.39%, #BC85FF 85.72%);
  --gradient-green: linear-gradient(91.92deg, #04997E 0.33%, #085399 98.38%);
  --gradient-dark: linear-gradient(121.26deg, rgba(30, 223, 177, 0.5) -0.35%, rgba(30, 223, 177, 0) 34.82%), linear-gradient(121.35deg, rgba(229, 24, 186, 0.4) -12.07%, rgba(229, 24, 186, 0) 44.14%), #3B0A6B;
  /* ////////////////////////// */
  --warning: #EEA02B;
  --success: #42BF1E;
  --info: #18A3C7;
  --error: #EC5555;
  --white: #FFFFFF;
  --dark: #282828;
  ;
  /* ////////////////////////// */
  --xlarge: 1728px;
  --large: 1400px;
  --medium: 768px;
  --small: 428px;
  --xsmall: 360px;

}

/* 
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf");
} */

.aic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.anim {
  transition: 400ms cubic-bezier(0.54, 0.21, 0.18, 1.35);
}

.anim2 {
  transition: 4000ms ease-in-out;
}


.hover:hover {
  cursor: pointer;
}

.scale50:hover {
  transform: scale(1.5);
}

.scale10:hover {
  transform: scale(1.01);
}

body {
  font-family: var(--font-family);
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  /* max-height: 100dvh; */
  color: var(--white);
  background: var(--interface-dark);

}

/* body {
  height: 100dvh;
  width: 100%;
  background-color: var(--b);
  font-family: var(--font-family);
} */

body>#root {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.dark-mode main {
  color: var(--dark);
  background: var(--interface-light);

}

h1 {
  font-style: italic;
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
}

h2 {
  font-style: italic;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

h3 {
  font-style: italic;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
}

h4 {
  font-style: italic;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.p1 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.p2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.p3 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.label1 {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
}

.label2 {
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
}

.label3 {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.label4 {
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
}

.button1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.button2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
}


@media screen and (max-width: 1400px) {

  /* @media screen and (max-width: 768px) { */
  h1 {

    font-size: 36px;
    line-height: 40px;
  }

  h2 {
    font-size: 32px;
    line-height: 36px;
  }

  h3 {
    font-size: 28px;
    line-height: 32px;
  }
}

@media screen and (max-width: 768px) {

  /* @media screen and (max-width: 428px) { */
  h1 {

    font-size: 24px;
    line-height: 28px;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
  }

  h3 {
    font-size: 18px;
    line-height: 20px;
  }

  h4 {
    font-size: 16px;
    line-height: 20px;
  }

  h5 {
    font-size: 14px;
    line-height: 16px;
  }

  .p1 {
    font-size: 14px;
    line-height: 20px;
  }

  .p2 {
    font-size: 12px;
    line-height: 18px;
  }

  .p3 {
    font-size: 12px;
    line-height: 18px;
  }



}

*::-webkit-scrollbar {
  display: none;
  /* cursor: pointer;
  width: 4px;
  height: 4px;
  box-sizing: border-box; */
}

*::-webkit-scrollbar-track {
  /* border-radius: 3px;
  border: 1px solid transparent;
  background: transparent;
  backdrop-filter: blur(4px); */
  display: none;
}

/* *::-webkit-scrollbar-thumb {
  background: #a292a4;
  width: 4px;
  height: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
} */

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.tooltip {
  position: relative;
}

.tooltip:before {
  content: attr(data-text);
  /* here's the magic */
  position: absolute;
  transform: translateY(-30px);
  min-width: 100px;
  padding: 5px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  display: none;
  font-size: 12px;
  opacity: 0;
  transition: 0.9s ease-in;
}

.tooltip:hover:before {
  opacity: 1 !important;
  display: block;
}

.tooltip:hover:before,
.tooltip:hover:after {
  display: block;
}

.tooltip:hover:after {
  opacity: 1 !important;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.star-rotate {
  animation-name: star-rotate-animation;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.54, 0.21, 0.18, 1.35);
  transform-origin: 55% 50%;
}

@keyframes star-rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.message-success {
  color: #42BF1E;
  font-weight: bold;
}

.message-error {
  color: #EC5555;
  font-weight: bold;
}

.container--container {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
}

.container--container>button {
  /* width: 100%; */
  align-self: center;
  margin-top: 34px;
}