.item--container {
    width: 100%;
    height: 72px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    justify-content: space-between !important;
    flex-shrink: 0;
}

.item-left {
    gap: 16px;
}

.item-left-icon {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
}

.item-left-text {
    flex-direction: column;
    align-items: flex-start;

}

.item-right {
    gap: 16px;
}

.item-right-text {
    flex-direction: column;
    align-items: flex-end;

}