.spinner--container {
  gap: 16px;
  font-family: var(--font-family);
  font-weight: bold;
}

.active-fullscreen {
  height: 100vh;
  width: 100vw;
  padding: 20px;
  top: 0px;
  left: 0px;
  z-index: 1002;
  position: fixed;
}

.spinner-icon {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  font-size: 4px;
  animation: spinner-loader 1.1s infinite ease;
  text-indent: -9999em;
  transform: translateZ(0);
}

@keyframes spinner-loader {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #777e90,
      1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2),
      -2.6em 0em 0 0em rgba(119, 126, 144, 0.5),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.7),
      1.8em -1.8em 0 0em #777e90, 2.5em 0em 0 0em rgba(119, 126, 144, 0.2),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2),
      -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.5),
      1.8em -1.8em 0 0em rgba(119, 126, 144, 0.7), 2.5em 0em 0 0em #777e90,
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2),
      -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2),
      1.8em -1.8em 0 0em rgba(119, 126, 144, 0.5),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.7), 1.75em 1.75em 0 0em #777e90,
      0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2),
      -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2),
      1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.5),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.7), 0em 2.5em 0 0em #777e90,
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2),
      -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2),
      1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.5),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.7), -1.8em 1.8em 0 0em #777e90,
      -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2),
      1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.5),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.7), -2.6em 0em 0 0em #777e90,
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2),
      1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.5),
      -2.6em 0em 0 0em rgba(119, 126, 144, 0.7), -1.8em -1.8em 0 0em #777e90;
  }
}