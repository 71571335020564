.button--container {
  padding: 8px 16px;
  gap: 10px;
  height: 48px;
  background: linear-gradient(90.03deg, #E518BA 0.03%, #EB5B13 99.97%);
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  color: #FFFFFF;
}


.button-secondary {
  padding: 12px 16px;
  gap: 10px;
  height: 48px;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
}

.button--container:disabled,
.button-secondary:disabled {
  background: #9A9A9A;
  color: #686868;
  cursor: not-allowed;
  transform: none !important;
}

.button--container:hover,
.button-secondary:hover {
  scale: 1.01;
}



@media (max-width: 1400px) {
  .button--container {
    padding: 13px 6px;
    height: 40px;
    font-size: 14px;
    line-height: 100%;
  }

  .button-secondary {
    padding: 13px 6px;
    height: 40px;
    font-size: 14px;
    line-height: 100%;
  }
}